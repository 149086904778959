.u-text-dark-gray {
    color: $dark-gray;
}

.u-text-success {
    color: $success-color;
}

.u-text-warning {
    color: $warning-color;
}

.u-font-bold {
    font-weight: 700;
}

.u-hidden {
    display: none;
}

.u-pointer-events-none {
    pointer-events: none;
}

.u-rounded {
    border-radius: $button-radius;
}

.u-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.u-break-all {
    word-break: break-all;
}
