.hero {
    z-index: 1;
    position: relative;
    height: rem(420);
    margin-bottom: rem(-50);
    background: $primary-color no-repeat center center;
    background-size: cover;
    overflow: hidden;

    @include breakpoint(medium) {
        height: rem(550);
    }

    &:after {
        z-index: 2;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        background-image: repeating-linear-gradient($hero-gradient-degree, rgba($hero-overlay-color, .1), rgba($hero-overlay-color, .25) 1px, transparent 1px, transparent 5px);
        background-size: 69px 521px;
    }

    &:before {
        z-index: 2;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($hero-overlay-color, 0.1);
        background-image: linear-gradient(to bottom, rgba($hero-overlay-color, 0.5) 0%, rgba($hero-overlay-color, 0) 35%, rgba($hero-overlay-color, 0) 100%)
    }

    .hero--background {
        width: 100%;
        height: 100%;
        max-width: none;
        overflow: hidden;
        position: relative;
        z-index: 2;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            max-width: none;
            width: auto;
            height: auto;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .hero--content {
        z-index: 3;
        position: absolute;
        padding-top: rem(164);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-top: $typogrid*2;
        text-align: center;

        * {
            color: #fff;
            text-shadow: rem(0 0 40) rgba(0, 0, 0, 0.85);
        }

        h1 {
            margin: 0;
            font-size: rem(40);
            line-height: 1.2;
            font-weight: $global-weight-bold;

            @include breakpoint(medium) {
                font-size: rem(64);
            }

            p,
            small {
                display: inline-block;
                font-size: rem(18);
                text-transform: uppercase;
                font-weight: $global-weight-normal;

                @include breakpoint(medium) {
                    font-size: rem(24);
                }
            }
        }

        p {
            text-transform: uppercase;
        }

        &__with-logo {
            padding-top: rem(80);
        }
    }

    .hero--logo img {
        background-color: rgba(255, 255, 255, 1);
        padding: rem(16);
        max-width: 250px;
        max-height: 150px;
        margin: 0px auto;
        margin-bottom: rem(16);
        box-shadow: 0 0 0.625rem rgba(5, 30, 63, 10%);
    }
}

.hero.css-mask-applied {

    &+.breadcrumbs-container {
        @include breakpoint(medium) {
            margin-top: rem(30);
        }
    }
}

.hero--video {
    height: calc(62vw + #{rem(50)});
    max-height: 95vh;
    max-height: calc(95vh + #{rem(50)});
    min-height: rem(500);
    margin-bottom: rem(-50);

    @include breakpoint(large) {
        min-height: rem(790);
    }

    video {
        display: none;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        max-width: none;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
        background: transparent no-repeat;
        background-size: cover;

        @include breakpoint(medium) {
            display: block;
        }
    }

    &:before {
        background-color: rgba(0, 0, 0, 0);
    }

    &.is-playing-video .hero--background {
        @include breakpoint(medium) {
            display: none;
        }
    }
}

.hero--box-container {
    margin-top: rem(50);

    @include breakpoint(large) {
        margin-top: 0;
        position: absolute;
        z-index: 99;
        transform: translateY(-123%);
        color: #fff;
        text-shadow: rem(0 0 40) rgba(0, 0, 0, 0.85);
    }
}

.hero--box {
    margin: rem(16 0);
    text-align: center;

    svg {
        display: block;
        min-height: rem(90);
        margin: rem(16) auto;
    }

    span {
        display: block;
        font-size: rem(20);
        font-family: $header-font-family;
    }

    strong {
        display: block;
        font-size: rem(24);
    }

    .icon-background,
    .icon {
        fill-rule: evenodd;
        fill: $primary-color;
    }

    .icon-background {
        fill: $lightcolor;
    }

    @include breakpoint(large) {
        .icon {
            fill: #fff;
        }

        .icon-background--date {
            fill: $primary-color;
        }

        .icon-background--location {
            fill: $secondary-color;
        }

        .icon-background--time {
            fill: $tertiary-color;
        }

        .icon-background--ticket {
            fill: $quaternary-color;
        }
    }
}

html.cycletour-de {
    .hero {
        &:after {
            background-image: repeating-linear-gradient($hero-gradient-degree, rgba(#fff, .05), rgba(#fff, .125) 1px, transparent 1px, transparent 5px);
        }

        &:before {
            top: rem(-220);
            right: rem(-205);
            left: rem(-200);
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 19%, transparent 40%, transparent 100%);
            transform: rotate(-7deg);
        }

        video,
        .hero--background {
            filter: brightness(1.1);
            -webkit-filter: brightness(1.1);
        }

        &.hero--video .hero--content {

            @include breakpoint(medium) {
                height: auto;
                min-height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 12rem;
            }


            h1 {
                font-size: rem(11);

                @include breakpoint(large) {
                    font-size: rem(20);
                }
            }
        }

        .line1,
        .line2,
        .line3,
        .line4,
        .line5 {
            display: block;
            line-height: 1;
            text-transform: uppercase;
            text-shadow: 0 0 rem(30) rgba(0, 0, 0, 0.25);
        }

        .line1 {
            font-size: 1.1em;
            line-height: 1.2;
        }

        .line2 {
            font-size: 6.5625em;
            font-weight: 700;
        }

        .line3,
        .line4 {
            font-size: 1.95em;
            font-weight: 500;
        }

        .line3 em,
        .line4 em {
            font-weight: 900;
            font-style: normal;
        }

        .line4 {
            margin-top: 0.3125em;
            letter-spacing: 0.08em;
        }

        .line5 {
            font-size: 6.2em;
            font-weight: 700;
        }
    }

    .hero--box-container {
        position: relative;
        transform: none;
        top: 0;
        opacity: 0.9;
        text-shadow: none;

        @include breakpoint(large) {
            margin-top: rem(-165);
        }

        .hero--box {
            margin: 0;
        }

        h2 {
            margin-top: rem(45);
            margin-bottom: rem(5);
            font-weight: 400;
            font-size: rem(90);
            line-height: rem(55);

            @include breakpoint(large) {
                margin-bottom: rem(45);
            }

            span {
                font-weight: 700;
                font-size: rem(20);
                text-transform: uppercase;
            }
        }
    }
}

html.firmenstaffel-de {

    $gradientUltrawalk: linear-gradient(54deg, #88e1a5 54%, #4793ff 95%);
    $gradientDigital: linear-gradient(117deg, #4793ff 7%, $secondary-color 20%);
    $gradientEvent: linear-gradient(333deg, $secondary-color 10%, #4793ff 20%);

    .hero.hero--video {
        display: flex;
        height: auto;
        max-height: none;

        --position: calc(50% + 4rem);
        background: transparent;

        @include breakpoint(large) {
            --position: 50%;
            min-height: max(min(95vh, rem(900)), 40rem);
        }

        $size: rem(25);

        &:before,
        &:after {
            z-index: 0;
            content: "";
            display: block;
            position: absolute;
            top: $size * -1;
            left: $size * -1;
            right: $size * -1;
            width: auto;
            bottom: calc(50% - 4.75rem);
            height: auto;
            background: $gradientDigital;
            transform: rotate(4deg);

            @include breakpoint(large) {
                top: $size * -2;
                left: $size * -2;
                right: auto;
                bottom: $size * -2;
                width: calc(50% + ($size * 2));
                height: auto;
            }
        }

        &:after {
            top: calc(50% + 4.75rem);
            bottom: $size * -1;
            background: $gradientEvent;

            @include breakpoint(large) {
                top: $size * -2;
                right: $size * -2;
                left: auto;
            }
        }

        .hero--content {
            position: relative;
            height: auto;
            min-height: 100%;
            display: flex;
            align-items: center;

            >.row {
                margin-top: rem(-35);

                >.columns {
                    padding-top: 3.5rem;
                    padding-bottom: 2.5rem;
                }
            }

            * {
                text-shadow: none;
            }

            h1 {
                margin: rem(0 0 20);
                text-transform: uppercase;
                line-height: 1;
            }

            small,
            p {
                margin: 0;
                letter-spacing: 2px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .button {
                letter-spacing: 0;
                border-width: 3px;
                box-shadow: none;
            }

            small {
                display: block;
                margin-bottom: rem(10);
                line-height: 1.1;
            }

            @include breakpoint(medium) {
                .columns {
                    padding-right: 3.875rem;
                    padding-left: 3.875rem;
                }
            }

            &:after {
                $size: rem(25);
                content: "";
                display: block;
                position: absolute;
                background: #fff;

                width: auto;
                height: $size;
                top: var(--position);
                bottom: auto;
                left: $size * -1;
                right: $size * -1;
                transform: rotate(4deg) translateY($size * -0.5);

                @include breakpoint(large) {
                    height: auto;
                    width: $size;
                    top: $size * -1;
                    bottom: $size * -1;
                    left: var(--position);
                    right: auto;
                    transform: rotate(4deg) translateX($size * -0.5);
                }
            }
        }

        .hero--background {
            position: absolute;
        }
    }

    .hero {
        background: $gradientEvent;

        video,
        .hero--background {
            mix-blend-mode: multiply;
            filter: grayscale(1);
        }
    }

    .hero:after {
        background: rgba(0, 0, 0, 0.1);
    }

    .hero.hero--style-variation1 {
        background: $gradientDigital;
    }

    .hero.hero--style-ultrawalk {
        background: $gradientUltrawalk;
    }
}
