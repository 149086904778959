//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Typography Helpers
//   6. Abide
//   7. Accordion
//   8. Accordion Menu
//   9. Badge
//  10. Breadcrumbs
//  11. Button
//  12. Button Group
//  13. Callout
//  14. Card
//  15. Close Button
//  16. Drilldown
//  17. Dropdown
//  18. Dropdown Menu
//  19. Forms
//  20. Label
//  21. Media Object
//  22. Menu
//  23. Meter
//  24. Off-canvas
//  25. Orbit
//  26. Pagination
//  27. Progress Bar
//  28. Responsive Embed
//  29. Reveal
//  30. Slider
//  31. Switch
//  32. Table
//  33. Tabs
//  34. Thumbnail
//  35. Title Bar
//  36. Tooltip
//  37. Top Bar

@import "../node_modules/foundation-sites/scss/util/util";

// font styles
$body-font-family: "web-roboto", "Roboto", sans-serif !default;
$header-font-family: "web-montserrat", "Montserrat", sans-serif !default;

$typogrid: 0.75rem;
$paragraph-lineheight: $typogrid * 2 !default;

$header-styles: (
  small: (
    "h1": (
      "font-size": 30,
      "margin-top": 3rem,
      "margin-bottom": 0.75rem
    ),
    "h2": (
      "font-size": 30,
      "line-height": $typogrid * 2,
      "margin-top": $typogrid * 4,
      "margin-bottom": $typogrid * 1
    ),
    "h3": (
      "font-size": 24,
      "line-height": $typogrid * 2,
      "margin-top": $typogrid * 4,
      "margin-bottom": $typogrid * 1
    ),
    "h4": (
      "font-size": 19,
      "line-height": $typogrid * 2,
      "margin-top": $typogrid * 4,
      "margin-bottom": 0
    ),
    "h5": (
      "font-size": 17,
      "line-height": $typogrid * 2,
      "margin-top": $typogrid * 2,
      "margin-bottom": 0
    ),
    "h6": (
      "font-size": 16,
      "line-height": $typogrid * 2,
      "margin-top": $typogrid * 2,
      "margin-bottom": 0
    )
  ),
  medium: (
    "h1": (
      "font-size": 36,
      "margin-top": 3rem,
      "margin-bottom": 0.75rem
    ),
    "h2": (
      "font-size": 36,
      "line-height": $typogrid * 4,
      "margin-top": $typogrid * 4,
      "margin-bottom": $typogrid * 1
    ),
    "h3": (
      "font-size": 30,
      "line-height": $typogrid * 3,
      "margin-top": $typogrid * 4,
      "margin-bottom": $typogrid * 1
    ),
    "h4": (
      "font-size": 24,
      "line-height": $typogrid * 3,
      "margin-top": $typogrid * 4,
      "margin-bottom": 0
    ),
    "h5": (
      "font-size": 18,
      "line-height": $typogrid * 3,
      "margin-top": $typogrid * 2,
      "margin-bottom": 0
    ),
    "h6": (
      "font-size": 16,
      "line-height": $typogrid * 3,
      "margin-top": $typogrid * 2,
      "margin-bottom": 0
    )
  )
) !default;

// 1. Global
// ---------

$global-font-size: 100% !default;
$global-width: rem-calc(1140) !default;
$global-lineheight: 1.5 !default;
$foundation-palette: (
  primary: #ff00ff,
  secondary: #767676,
  success: #2a9516,
  warning: #ffae00,
  alert: rgb(204, 0, 0)
) !default;
$light-gray: #e6e6e6 !default;
$medium-gray: #cacaca !default;
$dark-gray: #8a8a8a !default;
$black: #0a0a0a !default;
$white: #fefefe !default;
$body-background: $white !default;
$body-font-color: $black !default;
$body-font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !default;
$body-antialiased: true !default;
$global-margin: 1.5rem !default;
$global-padding: 1rem !default;
$global-weight-normal: 400 !default;
$global-weight-bold: 700 !default;
$global-radius: 0 !default;
$global-text-direction: ltr !default;
$global-flexbox: true !default;
$print-transparent-backgrounds: true !default;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1010px,
  xlarge: 1200px,
  xxlarge: 1440px
) !default;
$print-breakpoint: large !default;
$breakpoint-classes: (small medium large) !default;

// 3. The Grid
// -----------

$grid-row-width: $global-width !default;
$grid-column-count: 12 !default;
$grid-column-gutter: (
  small: 40px,
  medium: 60px
) !default;
$grid-column-align-edge: true !default;
$block-grid-max: 8 !default;

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family !default;
$header-font-weight: $global-weight-normal !default;
$header-font-style: normal !default;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace !default;
$header-color: inherit !default;
$header-lineheight: 1.4 !default;
$header-margin-bottom: 0.25rem !default;
$header-styles: (
  small: (
    "h1": (
      "font-size": 30
    ),
    "h2": (
      "font-size": 30
    ),
    "h3": (
      "font-size": 24
    ),
    "h4": (
      "font-size": 18
    ),
    "h5": (
      "font-size": 17
    ),
    "h6": (
      "font-size": 16
    )
  ),
  medium: (
    "h1": (
      "font-size": 36
    ),
    "h2": (
      "font-size": 36
    ),
    "h3": (
      "font-size": 30
    ),
    "h4": (
      "font-size": 24
    ),
    "h5": (
      "font-size": 18
    ),
    "h6": (
      "font-size": 16
    )
  )
) !default;
$header-text-rendering: optimizeLegibility !default;
$small-font-size: rem(13) !default;
$header-small-font-color: $medium-gray !default;
$paragraph-lineheight: 1.6 !default;
$paragraph-margin-bottom: $global-margin !default;
$paragraph-text-rendering: optimizeLegibility !default;
$code-color: $black !default;
$code-font-family: $font-family-monospace !default;
$code-font-weight: $global-weight-normal !default;
$code-background: $light-gray !default;
$code-border: 1px solid $medium-gray !default;
$code-padding: rem-calc(2 5 1) !default;
$anchor-color: $primary-color !default;
$anchor-color-hover: scale-color($anchor-color, $lightness: -30%) !default;
$anchor-text-decoration: none !default;
$anchor-text-decoration-hover: none !default;
$hr-width: $global-width !default;
$hr-border: 1px solid $medium-gray !default;
$hr-margin: rem-calc(20) auto !default;
$list-lineheight: $paragraph-lineheight !default;
$list-margin-bottom: $paragraph-margin-bottom !default;
$list-style-type: disc !default;
$list-style-position: outside !default;
$list-side-margin: 1.25rem !default;
$list-nested-side-margin: 1.25rem !default;
$defnlist-margin-bottom: 1rem !default;
$defnlist-term-weight: $global-weight-bold !default;
$defnlist-term-margin-bottom: 0.3rem !default;
$blockquote-color: $dark-gray !default;
$blockquote-padding: rem-calc(9 20 0 19) !default;
$blockquote-border: 1px solid $medium-gray !default;
$cite-font-size: rem-calc(13) !default;
$cite-color: $dark-gray !default;
$cite-pseudo-content: "\2014 \0020" !default;
$keystroke-font: $font-family-monospace !default;
$keystroke-color: $black !default;
$keystroke-background: $light-gray !default;
$keystroke-padding: rem-calc(2 4 0) !default;
$keystroke-radius: $global-radius !default;
$abbr-underline: 1px dotted $black !default;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.125 !default;
$lead-lineheight: 1.6 !default;
$subheader-lineheight: 1.4 !default;
$subheader-color: $dark-gray !default;
$subheader-font-weight: $global-weight-normal !default;
$subheader-margin-top: 0.2rem !default;
$subheader-margin-bottom: 0.5rem !default;
$stat-font-size: 2.5rem !default;

// 6. Abide
// --------

$abide-inputs: true !default;
$abide-labels: true !default;
$input-background-invalid: get-color(alert) !default;
$form-label-color-invalid: get-color(alert) !default;
$input-error-color: get-color(alert) !default;
$input-error-font-size: rem-calc(12) !default;
$input-error-font-weight: $global-weight-bold !default;

// 7. Accordion
// ------------

$accordion-background: $white !default;
$accordion-plusminus: true !default;
$accordion-title-font-size: rem-calc(12) !default;
$accordion-item-color: $primary-color !default;
$accordion-item-background-hover: $light-gray !default;
$accordion-item-padding: 1.25rem 1rem !default;
$accordion-content-background: $white !default;
$accordion-content-border: 1px solid $light-gray !default;
$accordion-content-color: $body-font-color !default;
$accordion-content-padding: 1rem !default;

// 8. Accordion Menu
// -----------------

$accordionmenu-arrows: true !default;
$accordionmenu-arrow-color: $primary-color !default;
$accordionmenu-arrow-size: 6px !default;

// 9. Badge
// --------

$badge-background: $primary-color !default;
$badge-color: $white !default;
$badge-color-alt: $black !default;
$badge-palette: $foundation-palette !default;
$badge-padding: 0.3em !default;
$badge-minwidth: 2.1em !default;
$badge-font-size: 0.6rem !default;

// 10. Breadcrumbs
// ---------------

$breadcrumbs-margin: 0 0 $global-margin 0 !default;
$breadcrumbs-item-font-size: rem-calc(11) !default;
$breadcrumbs-item-color: $primary-color !default;
$breadcrumbs-item-color-current: $black !default;
$breadcrumbs-item-color-disabled: $medium-gray !default;
$breadcrumbs-item-margin: 0.75rem !default;
$breadcrumbs-item-uppercase: true !default;
$breadcrumbs-item-slash: true !default;

// 11. Button
// ----------

$button-padding: 0.6875em 2.5em !default;
$button-margin: 0 !default;
$button-fill: solid !default;
$button-background: $primary-color !default;
$button-background-hover: scale-color($button-background, $lightness: -15%) !default;
$button-color: #fff !default;
$button-color-alt: #fff !default;
$button-radius: $global-radius !default;
$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 1rem,
  large: 1.25rem
) !default;
$button-palette: $foundation-palette !default;
$button-opacity-disabled: 0.3 !default;
$button-background-hover-lightness: -10% !default;
$button-hollow-hover-lightness: -20% !default;
$button-hollow-width: rem-calc(2) !default;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out !default;

// 12. Button Group
// ----------------

$buttongroup-margin: 1rem !default;
$buttongroup-spacing: 1px !default;
$buttongroup-child-selector: ".button" !default;
$buttongroup-expand-max: 6 !default;
$buttongroup-radius-on-each: true !default;

// 13. Callout
// -----------

$callout-background: $white !default;
$callout-background-fade: 85% !default;
$callout-border: 1px solid rgba($black, 0.25) !default;
$callout-margin: 0 0 1rem 0 !default;
$callout-padding: 1rem !default;
$callout-font-color: $body-font-color !default;
$callout-font-color-alt: $body-background !default;
$callout-radius: $global-radius !default;
$callout-link-tint: 30% !default;

// 14. Card
// --------

$card-background: $white !default;
$card-font-color: $body-font-color !default;
$card-divider-background: $light-gray !default;
$card-border: 1px solid $light-gray !default;
$card-shadow: none !default;
$card-border-radius: $global-radius !default;
$card-padding: $global-padding !default;
$card-margin: $global-margin !default;

// 15. Close Button
// ----------------

$closebutton-position: right top !default;
$closebutton-offset-horizontal: (
  small: 0.66rem,
  medium: 1rem
) !default;
$closebutton-offset-vertical: (
  small: 0.33em,
  medium: 0.5rem
) !default;
$closebutton-size: (
  small: 1.5em,
  medium: 2em
) !default;
$closebutton-lineheight: 1 !default;
$closebutton-color: $dark-gray !default;
$closebutton-color-hover: $black !default;

// 16. Drilldown
// -------------

$drilldown-transition: transform 0.15s linear !default;
$drilldown-arrows: true !default;
$drilldown-arrow-color: $primary-color !default;
$drilldown-arrow-size: 6px !default;
$drilldown-background: $white !default;

// 17. Dropdown
// ------------

$dropdown-padding: 1rem !default;
$dropdown-background: $body-background !default;
$dropdown-border: 1px solid $medium-gray !default;
$dropdown-font-size: 1rem !default;
$dropdown-width: 300px !default;
$dropdown-radius: $global-radius !default;
$dropdown-sizes: (
  tiny: 100px,
  small: 200px,
  large: 400px
) !default;

// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: true !default;
$dropdownmenu-arrow-color: $anchor-color !default;
$dropdownmenu-arrow-size: 6px !default;
$dropdownmenu-min-width: 200px !default;
$dropdownmenu-background: $white !default;
$dropdownmenu-border: 1px solid $medium-gray !default;

// 19. Forms
// ---------

$fieldset-border: 1px solid $medium-gray !default;
$fieldset-padding: rem-calc(20) !default;
$fieldset-margin: rem-calc(18 0) !default;
$legend-padding: rem-calc(0 3) !default;
$form-spacing: rem-calc(16) !default;
$helptext-color: $black !default;
$helptext-font-size: rem-calc(13) !default;
$helptext-font-style: italic !default;
$input-prefix-color: $black !default;
$input-prefix-background: $light-gray !default;
$input-prefix-border: 1px solid $medium-gray !default;
$input-prefix-padding: 1rem !default;
$form-label-color: $black !default;
$form-label-font-size: rem-calc(14) !default;
$form-label-font-weight: $global-weight-normal !default;
$form-label-line-height: 1.8 !default;
$select-background: $white !default;
$select-triangle-color: $dark-gray !default;
$select-radius: $global-radius !default;
$input-color: $black !default;
$input-placeholder-color: $medium-gray !default;
$input-font-family: inherit !default;
$input-font-size: rem-calc(15) !default;
$input-font-weight: $global-weight-normal !default;
$input-background: $white !default;
$input-background-focus: $white !default;
$input-background-disabled: $light-gray !default;
$input-border: 1px solid $medium-gray !default;
$input-border-focus: 1px solid $dark-gray !default;
$input-shadow: inset 0 1px 2px rgba($black, 0.1) !default;
$input-shadow-focus: 0 0 5px $medium-gray !default;
$input-cursor-disabled: not-allowed !default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out !default;
$input-number-spinners: true !default;
$input-radius: $global-radius !default;
$form-button-radius: $global-radius !default;

// 20. Label
// ---------

$label-background: $primary-color !default;
$label-color: $white !default;
$label-color-alt: $black !default;
$label-palette: $foundation-palette !default;
$label-font-size: 0.8rem !default;
$label-padding: 0.33333rem 0.5rem !default;
$label-radius: $global-radius !default;

// 21. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin !default;
$mediaobject-section-padding: $global-padding !default;
$mediaobject-image-width-stacked: 100% !default;

// 22. Menu
// --------

$menu-margin: 0 !default;
$menu-margin-nested: 1rem !default;
$menu-item-padding: 0.7rem 1rem !default;
$menu-item-color-active: $white !default;
$menu-item-background-active: get-color(primary) !default;
$menu-icon-spacing: 0.25rem !default;
$menu-item-background-hover: $light-gray !default;
$menu-border: $light-gray !default;

// 23. Meter
// ---------

$meter-height: 1rem !default;
$meter-radius: $global-radius !default;
$meter-background: $medium-gray !default;
$meter-fill-good: $success-color !default;
$meter-fill-medium: $warning-color !default;
$meter-fill-bad: $alert-color !default;

// 24. Off-canvas
// --------------

$offcanvas-size: 250px !default;
$offcanvas-vertical-size: 250px !default;
$offcanvas-background: #fff !default;
$offcanvas-shadow: 0 0 1rem rgba(0, 0, 0, 0.3) !default;
$offcanvas-push-zindex: 1 !default;
$offcanvas-overlap-zindex: 501 !default;
$offcanvas-reveal-zindex: 1 !default;
$offcanvas-transition-length: 0.5s !default;
$offcanvas-transition-timing: ease !default;
$offcanvas-fixed-reveal: true !default;
$offcanvas-exit-background: rgba($white, 0.5) !default;
$maincontent-class: "off-canvas-content" !default;

// 25. Orbit
// ---------

$orbit-bullet-background: $medium-gray !default;
$orbit-bullet-background-active: $dark-gray !default;
$orbit-bullet-diameter: 1.2rem !default;
$orbit-bullet-margin: 0.1rem !default;
$orbit-bullet-margin-top: 0.8rem !default;
$orbit-bullet-margin-bottom: 0.8rem !default;
$orbit-caption-background: rgba($black, 0.5) !default;
$orbit-caption-padding: 1rem !default;
$orbit-control-background-hover: rgba($black, 0.5) !default;
$orbit-control-padding: 1rem !default;
$orbit-control-zindex: 10 !default;

// 26. Pagination
// --------------

$pagination-font-size: rem-calc(14) !default;
$pagination-margin-bottom: $global-margin !default;
$pagination-item-color: $black !default;
$pagination-item-padding: rem-calc(3 10) !default;
$pagination-item-spacing: rem-calc(1) !default;
$pagination-radius: $global-radius !default;
$pagination-item-background-hover: $light-gray !default;
$pagination-item-background-current: $primary-color !default;
$pagination-item-color-current: $white !default;
$pagination-item-color-disabled: $medium-gray !default;
$pagination-ellipsis-color: $black !default;
$pagination-mobile-items: false !default;
$pagination-mobile-current-item: false !default;
$pagination-arrows: true !default;

// 27. Progress Bar
// ----------------

$progress-height: 1rem !default;
$progress-background: $medium-gray !default;
$progress-margin-bottom: $global-margin !default;
$progress-meter-background: $primary-color !default;
$progress-radius: $global-radius !default;

// 28. Responsive Embed
// --------------------

$responsive-embed-margin-bottom: rem-calc(16) !default;
$responsive-embed-ratios: (
  default: 4 by 3,
  widescreen: 16 by 9
) !default;

// 29. Reveal
// ----------

$reveal-background: $white !default;
$reveal-width: 600px !default;
$reveal-max-width: $global-width !default;
$reveal-padding: $global-padding !default;
$reveal-border: 1px solid $medium-gray !default;
$reveal-radius: $global-radius !default;
$reveal-zindex: 1005 !default;
$reveal-overlay-background: rgba($black, 0.45) !default;

// 30. Slider
// ----------

$slider-width-vertical: 0.5rem !default;
$slider-transition: all 0.2s ease-in-out !default;
$slider-height: 0.5rem !default;
$slider-background: $light-gray !default;
$slider-fill-background: $medium-gray !default;
$slider-handle-height: 1.4rem !default;
$slider-handle-width: 1.4rem !default;
$slider-handle-background: $primary-color !default;
$slider-opacity-disabled: 0.25 !default;
$slider-radius: $global-radius !default;

// 31. Switch
// ----------

$switch-background: $medium-gray !default;
$switch-background-active: $primary-color !default;
$switch-height: 2rem !default;
$switch-height-tiny: 1.5rem !default;
$switch-height-small: 1.75rem !default;
$switch-height-large: 2.5rem !default;
$switch-radius: $global-radius !default;
$switch-margin: $global-margin !default;
$switch-paddle-background: $white !default;
$switch-paddle-offset: 0.25rem !default;
$switch-paddle-radius: $global-radius !default;
$switch-paddle-transition: all 0.25s ease-out !default;

// 32. Table
// ---------

$table-background: $white !default;
$table-color-scale: 5% !default;
$table-border: 1px solid smart-scale($table-background, $table-color-scale) !default;
$table-padding: rem-calc(8 10 10) !default;
$table-hover-scale: 2% !default;
$table-row-hover: darken($table-background, $table-hover-scale) !default;
$table-row-stripe-hover: darken($table-background, $table-color-scale + $table-hover-scale) !default;
$table-is-striped: true !default;
$table-striped-background: smart-scale($table-background, $table-color-scale) !default;
$table-stripe: even !default;
$table-head-background: smart-scale($table-background, $table-color-scale / 2) !default;
$table-head-row-hover: darken($table-head-background, $table-hover-scale) !default;
$table-foot-background: smart-scale($table-background, $table-color-scale) !default;
$table-foot-row-hover: darken($table-foot-background, $table-hover-scale) !default;
$table-head-font-color: $body-font-color !default;
$table-foot-font-color: $body-font-color !default;
$show-header-for-stacked: false !default;

// 33. Tabs
// --------

$tab-margin: 0 !default;
$tab-background: $white !default;
$tab-color: $primary-color !default;
$tab-background-active: $light-gray !default;
$tab-active-color: $primary-color !default;
$tab-item-font-size: rem-calc(12) !default;
$tab-item-background-hover: $white !default;
$tab-item-padding: 1.25rem 1.5rem !default;
$tab-expand-max: 6 !default;
$tab-content-background: $white !default;
$tab-content-border: $light-gray !default;
$tab-content-color: $body-font-color !default;
$tab-content-padding: 1rem !default;

// 34. Thumbnail
// -------------

$thumbnail-border: solid 4px $white !default;
$thumbnail-margin-bottom: $global-margin !default;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2) !default;
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5) !default;
$thumbnail-transition: box-shadow 200ms ease-out !default;
$thumbnail-radius: $global-radius !default;

// 35. Title Bar
// -------------

$titlebar-background: $black !default;
$titlebar-color: $white !default;
$titlebar-padding: 0.5rem !default;
$titlebar-text-font-weight: bold !default;
$titlebar-icon-color: $white !default;
$titlebar-icon-color-hover: $medium-gray !default;
$titlebar-icon-spacing: 0.25rem !default;

// 36. Tooltip
// -----------

$has-tip-font-weight: $global-weight-bold !default;
$has-tip-border-bottom: dotted 1px $dark-gray !default;
$tooltip-background-color: $black !default;
$tooltip-color: $white !default;
$tooltip-padding: 0.75rem !default;
$tooltip-font-size: $small-font-size !default;
$tooltip-pip-width: 0.75rem !default;
$tooltip-pip-height: $tooltip-pip-width * 0.866 !default;
$tooltip-radius: $global-radius !default;

// 37. Top Bar
// -----------

$topbar-padding: 0.5rem !default;
$topbar-background: $light-gray !default;
$topbar-submenu-background: $topbar-background !default;
$topbar-title-spacing: 0.5rem 1rem 0.5rem 0 !default;
$topbar-input-width: 200px !default;
$topbar-unstack-breakpoint: medium !default;
