.browserupgrade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
    text-align: center;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

body {
    visibility: visible;
    opacity: 1;
    margin: 0;
    padding: 0;
}

.pagewrapper {
    position: relative;
}

html.has-socialbar .pagewrapper {
    margin-top: rem(44);
    @include breakpoint(large) {
        margin-top: 0;
    }
}

// hide texts while webfonts are loading
.webfonts-loading {
    h2,h3,h4,h5,h6,p,span,strong,li,a {
        visibility: hidden;
    }
}
// switch font-family once webfonts have loaded
.webfonts-loaded body, .no-js body {
    font-family: 'Cairo', #{$body-font-family};
}

.pagecontent {
    padding-bottom: 1px; // required for negative margin buttons (from angled layouts) and footer-divider
}

html.no-hero {
    .pagecontent {
        padding-top: rem(100); // overridden for small-breakpoint in _pageheader.scss!
    }
}

.row.fullwidth {
    width: 100%;
    max-width: none;
}

.row-background {
    position: relative;
}

.row-background:not([data-mask="0,0,0,0"]) {
    overflow: hidden;
}

.row-background:not([data-mask="0,0,0,0"]) > .row-paddingbox {
    padding: rem(60 0 70);
    @include breakpoint(large) {
        padding: rem(110 0 130);
    }
}

.row-full > * {
    margin-left: auto;
    margin-right: auto;
}
.row-full + .row > * {
    padding-top: rem(30);
}

.row > .sidebar {
    position: relative;
    padding-bottom: rem(30);
    background: $sidebar-background;

    // prevent margin-top on first child element due to padding-top of .sidebar
    > :first-child {
        margin-top: $typogrid*6 !important;
        > :first-child {
            margin-top: 0 !important;
        }
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        width: rem(1920);
        width: 50vw;
        background: inherit;
    }
}

/* row fullwidth images */
@media screen {
    .image--fullwidth-container {
        min-height: rem(300);

        @include breakpoint(medium down) {
            margin-left: rem(-30);
            margin-right: rem(-30);
            figure {
                clip-path: none !important;
            }
            img {
                width: 100%;
            }
        }

        @include breakpoint(large) {
            display: block;
            width: 100%;
            height: 100%;

            figure {
                //z-index: -1;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                overflow: hidden;
            }

            img {
                /* object-fit: cover */
                min-width: 100%;
                max-width: none;
                min-height: 100%;
                width: auto;
                height: auto;
            /*    transform: translate(-50%,-50%);
                position: relative;
                top: 50%;
                left: 50%; */
            }
        }

    }

    html.css-mask-supported .image--fullwidth {
        @include breakpoint(large) {
            z-index: -1;
        }
    }

    .column-1 .image--fullwidth {
        min-height: rem(150);
    }

    .column-1 .image--fullwidth-container, .column-2.small-order-1 .image--fullwidth-container {
        position: relative;
        top: rem(-70);
        margin-bottom: rem(-30);

        @include breakpoint(large) {
            position: static;
            margin: auto;
            top: auto;
        }
    }

    @include breakpoint(large) {
        .column-1.large-3 .image--fullwidth-container figure {
            right: calc(50% + 316px);
        }
        .column-1.large-4 .image--fullwidth-container figure {
            right: calc(50% + 216px);
        }
        .column-1.large-5 .image--fullwidth-container figure {
            right: calc(50% + 125px);
        }
        .column-1.large-6 .image--fullwidth-container figure {
            right: calc(50% + 20px);
        }
        .column-1.large-7 .image--fullwidth-container figure {
            right: calc(50% - 160px);
        }
    }

    .column-2 .image--fullwidth-container, .column-1.small-order-2 .image--fullwidth-container {
        position: relative;
        bottom: rem(-70);
        margin-top: rem(-30);

        @include breakpoint(large) {
            position: static;
            margin: auto;
            bottom:    auto;
        }
    }

    .image--fullwidth-container--single {
        min-height: 0;
       @include breakpoint(medium down) {
           position: relative;
           top: rem(-70);
           margin-bottom: rem(-140);
       }
       @include breakpoint(medium) {
           img {
               max-width: 100%;
           }
       }
       @include breakpoint(large) {
           min-height: rem(500);
           figure {
               width: 100%;
               img {
                   transform: translate(-50%, -50%);
                   position: relative;
                   top: 50%;
                   left: 50%;
               }
           }
       }
   }
}

.breadcrumbs-container {
    position: relative;
    z-index: 1;
    margin-top: rem(65);
    margin-bottom: rem(-50);

    .breadcrumbs {
        margin: 0 0 0;
    }
}
