form input.button {
    top: 0;
    @include button;
    line-height: $typogrid * 2;
}

.button {
    @include button;
    width: auto;
    font-weight: $global-weight-bold;
    text-transform: uppercase;
    line-height: $typogrid * 2;
    text-shadow: 0 0 rem(5) rgba(0,0,0,0.2);
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), 0 2px 4px rgba(0,0,0,0.05), 0 4px 8px rgba(0,0,0,0.05), 0 8px 16px rgba(0,0,0,0.05), 0 16px 32px rgba(0,0,0,0.05), 0 32px 64px rgba(0,0,0,0.05);
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;

    // Sizes
    @each $size, $value in map-remove($button-sizes, default) {
        &.#{$size} {
            font-size: $value;
        }
    }

    &:hover {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.05), 0 8px 16px rgba(0, 0, 0, 0.05), 0 16px 32px rgba(0, 0, 0, 0.05), 0 32px 64px rgba(0, 0, 0, 0.05), 0 3px 10px rgba(0,0,0,0.3);
    }

    // full width buttons
    &.fullwidth { @include button-expand; }

    // Colors
    @each $name, $styles in $button-styles {
        &.#{$name} {
            @include button-style(map-get($styles, 'background'), map-get($styles, 'background-hover'), map-get($styles, 'text-color'));
        }
    }

    &.white {
        @include button-style($white, #f7f7f7, $primary-color);
        text-shadow: none;
    }

    // Hollow style
    &.outline {
        @include button-hollow;
        @include button-hollow-style;
        padding-top: 0.625em;
        padding-bottom: 0.625em;
        text-shadow: none;
        box-shadow: none;

        @each $name, $styles in $button-styles {
            &.#{$name} {
                @include button-hollow-style(map-get($styles, 'background'), map-get($styles, 'background-hollow-scale'));
            }
        }

        &.white {
            @include button-hollow-style(#fff, -5%);
            background-color: rgba(0,0,0,0);
            transition: background-color 0.3s ease-in-out;
            &:hover {
                background-color: rgba(0,0,0,0.15);
            }
        }
    }


    // Disabled style
    &.disabled,
    &[disabled] {
        @include button-disabled;
        //pointer-events: none;

        @each $name, $styles in $button-styles {
            &.#{$name} {
                @include button-disabled(map-get($styles, 'background'));
            }
        }

        &.white {
            @include button-disabled($white);
            color: $primary-color;
        }
    }
    /*
        // Dropdown arrow
        &.dropdown {
            @include button-dropdown;

            @if $button-fill == hollow {
                &::after {
                    border-top-color: $button-background;
                }
            }
        }

        // Button with dropdown arrow only
        &.arrow-only::after {
            top: -0.1em;
            float: none;
            margin-#{$global-left}: 0;
        }
    */
}
