.c-icongrid {
    fill: $body-font-color;
    color: $body-font-color;

    &__icon {
        display: block;
        width: rem(100);
        height: rem(65);
        max-width: 100%;
        margin: rem(32) auto;
        fill: inherit;

        @include breakpoint(medium) {
            width: rem(120);
            height: rem(80);
        }
    }

    &__title {
        display: block;
        margin: rem(-32 4 32);
        text-align: center;
        color: inherit;
        word-break: break-word;
    }

    &--primary {
        fill: get-color(primary);
    }

    &--secondary {
        fill: get-color(secondary);
    }

    &--tertiary {
        fill: get-color(tertiary);
    }

    &--quaternary {
        fill: get-color(quaternary);
    }

    &--white {
        fill: #fff;
        color: #fff;
    }
}
