.countdown {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: rem(0 15);

    .block {
        display: block;
        margin: $typogrid
    }

    .value {
        display: block;
        width: rem(80);
        height: rem(80);
        margin: 0 0 rem(5);
        background: #fafafa linear-gradient(to bottom, #fafafa 0%, #f8f8f8 100%);
        border-radius: $button-radius;
        box-shadow: 0 rem(2) rem(5) rgba(#000, 0.2);
        font-weight: $global-weight-bold;
        font-family: $header-font-family;
        line-height: rem(80);
        font-size: rem(34);
        color: $body-font-color;
    }

    .label {
        font-size: $small-font-size;
        text-transform: uppercase;
    }
}