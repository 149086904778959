ul.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: block;
        padding: rem(9);
        text-align: center;
    }

    a {
        display: inline-block;
        padding: rem(3);
        border: 1px solid $lightcolor;
        outline: none;

        &:hover {
            border-color: $primary-color;
        }
    }


    &.size-248x140 {
        li {
            width: 100%;

            @include breakpoint(medium) {
                width: 50%;
            }

            @include breakpoint(large) {
                width: 25%;
            }
        }
    }

    &.size-154x87 {
        li {
            width: 50%;

            @include breakpoint(medium) {
                width: 25%;
            }

            @include breakpoint(large) {
                width: 16.6%;
            }
        }
    }

    &.size-92x92 {
        li {
            width: 50%;

            @include breakpoint(medium) {
                width: 25%;
            }

            @include breakpoint(large) {
                width: 10%;
            }
        }
    }

}

/*
.modaal-gallery-item img {
    max-height: 85vh;
    width: auto !important;
    max-width: 100%;
}
*/