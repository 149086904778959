.l-pricing-table {

    .column {

        margin-bottom: $global-margin;

        @include breakpoint(medium) {
            border-left: solid 1px rgba(get-color(secondary), 0.2);
            &:last-child {
                border-right: solid 1px rgba(get-color(secondary), 0.2);
            }
        }

        @include breakpoint(medium only) {
            &:nth-child(3n + 0) {
                border-right: solid 1px rgba(get-color(secondary), 0.2);
            }
        }

    }

}

.c-pricing-table {

    width: 100%;
    margin-bottom: 0;
    margin-left: 0 !important;
    text-align: center;
    list-style-type: none;
    background: rgba(get-color(secondary), 0.08);

    li {
        padding: 0.875rem 1.125rem;
    }

    .c-pricing-table__title {
        background-color: get-color(primary);
        color: $white;
        font-weight: $global-weight-bold;
        font-size: $lead-font-size;
        text-transform: uppercase;
    }

    .c-pricing-table__image {
        padding: 0;
    }

    .c-pricing-table__description {
        font-weight: $global-weight-bold;
    }

    .c-pricing-table__highlights {
        padding: 0;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                border-top: dotted 1px $medium-gray;
            }
        }
    }

    .c-pricing-table__price {
        background-color: rgba(get-color(secondary), 0.2);
        font-size: $lead-font-size;
        font-weight: $global-weight-bold;
    }

    @include breakpoint(medium) {
        display: flex;
        flex-direction: column;
        height: 100%;
        li {
            display: flex;
		    flex-direction: column;
        }
        .c-pricing-table__highlights {
            flex-grow: 1;
        }
    }

  }
