h1,h2,h3,h4,h5,h6 {
    small {
        display: block;
        text-transform: uppercase;
        line-height: $typogrid*4;
        margin: $typogrid*-2 0 0;
    }

    a {
        color: inherit;

        &:hover {
            color: inherit;
        }
    }
}

@include breakpoint('medium') {
    h1,h2 {
        line-height: $typogrid*4;
    }
    h3,h4,h5 {
        line-height: $typogrid*3;
    }
}
.lead {
    font-size: rem(18);
    line-height: $typogrid * 2;
    margin-top: $typogrid * 2;
}

small {
    line-height: 1.4999rem;
}

.sidebar {
    $headers: map-get($header-styles, small);
    @each $header, $header-defs in $headers {
        $font-size-temp: 1rem;
        #{$header} {
            $font-size-temp: rem-calc(map-get($header-defs, font-size));
            font-size: $font-size-temp;
        }
    }
}

a {
    transition: color 0.3s ease-in-out;
}

.pagecontent ul {
    margin-left: rem(40);
}

figure {
    text-align: center;
}

@each $name, $color in map-remove($foundation-palette, 'light') {
    .background--#{$name} * {
        color: #fff;
    }
}

html.firmenstaffel-de .background--secondary * {
    color: $black;
}

.partner-headline {
    margin-top: $typogrid*1;

    @include breakpoint(medium) {
        margin-top: $typogrid*4;
    }
}
