$form-radius: 2px;

.c-form {
    display: block;
    width: 100%;

    $column-gutter: rem(16);

    .row {
        margin-left: $column-gutter * -1;
        margin-right: $column-gutter * -1;
    }
    .column, .columns {
        padding: 0 $column-gutter;
    }

    &__fieldset {
        margin: 0 0 ($global-margin * 2);
        padding: rem(16 24 2);
        border: 1px solid #d4d4d4;
        border-radius: $form-radius;

        &--error {
            border: 3px solid $warning-color;
            color: $warning-color;

            p {
                font-weight: 700;
            }
        }

        &--success {
            border: 3px solid $primary-color;
            color: $primary-color;

            p {
                font-weight: 700;
            }
        }
    }

    &__legend {
        margin-left: rem(-6);
        padding: rem(0 6);
        font-weight: 700;
        background: #fff;
        color: inherit;
    }

    &__inputgroup {
        position: relative;
        display: block;
        margin: rem(0 0 26);

        &.has-error {
            &:after {
                content: "";
                position: absolute;
                left: rem(-16);
                top: rem(2);
                bottom: rem(-5);
                width: rem(4);
                background: $warning-color;
            }
        }
    }

    &__label {
        display: block;
        margin: rem(0 0 3);
        font-weight: 700;
        color: $body-font-color;

        &[for] {
            cursor: pointer;
        }

        &--checkbox, &--radio {
            position: relative;
            padding-left: rem(32);
            font-weight: 400;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 1px;
                left: 0;
                width: rem(22);
                height: rem(22);
                border: 2px solid currentColor;
                background: #fff;
            }

            &:after {
                content: "";
                position: absolute;
                top: 7px;
                left: 4px;
                width: 14px;
                height: 7px;
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
                border: solid;
                border-width: 0 0 3px 3px;
                border-top-color: transparent;
                opacity: 0;
                background: transparent;
            }
        }

        &--radio {
            &:before {
                border-radius: 100%;
            }

            &:after {
                display: block;
                top: 7px;
                left: 6px;
                width: 10px;
                height: 10px;
                border: none;
                background: $body-font-color;
                border-radius: 100%;
            }
        }
    }

    &__hint {
        display: block;
        margin: rem(3 0 0);
        font-size: 100%;
        font-weight: 400;
        color: $dark-gray;
    }

    &__inputerror {
        display: none;
        margin: rem(5 0 8);
        font-size: 100%;
        font-weight: 700;
        color: $warning-color;

        .c-form__inputgroup.has-error & {
            display: block;
        }
    }

    &__input {
        display: block;
        box-sizing: border-box;
        outline: none;
        -webkit-appearance: none;
	    -moz-appearance: none;
        appearance: none;
        width: 100%;
        padding: 10px;
        border: 1px  solid  $medium-gray;
        border-radius: $form-radius;
        background-color: white;
        color: $body-font-color;

        .c-form__inputgroup.has-error & {
            outline: 3px solid $warning-color;
            outline-offset: -2px;
        }

        &:focus {
            //border-color: $dark-gray;
            outline: 2px solid $primary-color;
            outline-offset: -2px;
        }

        &::placeholder {
            color: $dark-gray;
        }

        &--textarea{
            min-height: 100px;
	        resize: vertical;
        }

        &--checkbox {
            position: absolute;
            width: auto;
            opacity: 0;

            &:checked + .c-form__label--checkbox:after {
                opacity: 1;
            }

            &:focus + .c-form__label--checkbox:before {
                border-color: $primary-color;
            }
        }

        &--radio {
            position: absolute;
            width: auto;
            opacity: 0;

            &:checked + .c-form__label--radio:after {
                opacity: 1;
            }

            &:focus + .c-form__label--radio:before {
                border-color: $primary-color;
            }
        }

        &--select {
            $arrow-color: str-slice(inspect($body-font-color), 2);
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23#{$arrow-color}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat, repeat;
            background-position: right .7em top 50%, 0 0;
            background-size: .65em auto, 100%;

            &::-ms-expand {
                display: none;
            }

            &.is-disabled, &:disabled, &[aria-disabled=true] {
                background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
            }

            option {
                font-weight:normal;
            }
        }
    }

}

form {
    background-color: #fff;
}

form:not(.c-form) {

  .field {
    position: relative;
    margin: ($typogrid * 3) 0;
    background-color: inherit;
  }

  input,
  textarea {
    z-index: 2;
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    padding: rem(0 6);
    font-size: rem(16);
    line-height: $typogrid * 2;
    border: none;
    border-bottom: 1px solid #d4d4d4;
    outline: none;
    color: $body-font-color;
    background-color: inherit;

    &.filled,
    &.filled:valid {
      position: relative;
      top: $typogrid * 2;
      margin-top: $typogrid * -2;
      margin-bottom: $typogrid * 5;
    }

    &:focus {
      border-color: $primary-color;
    }
  }

  select {
    display: block;
    position: relative;
    appearance: none;
    width: 100%;
    height: rem(34);
    padding: rem(6);
    top: $typogrid * 2.25;
    margin-top: $typogrid * -2.25;
    margin-bottom: $typogrid * 5.25;
    border: 1px solid #d4d4d4;
    border-radius: $select-radius;
    background-color: $white;
    font-size: rem(16);
    line-height: normal;
    color: $body-font-color;
  }

  label {
    z-index: 1;
    position: absolute;
    top: 0;
    display: block;
    line-height: $typogrid * 2;
    color: $primary-color;
    text-transform: uppercase;
    font-size: rem(12);
    font-weight: $global-weight-bold;
    span.error {
      color: get-color(warning);
    }
  }

  &.background--primary,
  html:not(.firmenstaffel-de) &.background--secondary,
  &.background--tertiary,
  &.background--tertiary,
  &.background--quaternary {
    input,
    textarea {
      color: #fff;
      border-color: #fff;
    }
    label {
      color: #fff;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #fff;
      opacity: 0.7;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      opacity: 0.7;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      opacity: 0.7;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
      opacity: 0.7;
    }
    .button.outline {
      color: #fff;
      border-color: #fff;
    }
  }

  html.firmenstaffel-de &.background--secondary,
  &.background--light {
    input,
    textarea {
      color: $body-font-color;
      border-color: rgba($body-font-color, 0.4);
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: $body-font-color;
      opacity: 0.7;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: $body-font-color;
      opacity: 0.7;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: $body-font-color;
      opacity: 0.7;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $body-font-color;
      opacity: 0.7;
    }
  }
}
