.pageheader {
    z-index: 100;
    position: absolute;
    top: rem(-120);
    padding-top: rem(120);
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0);
    transition: top .25s ease-in-out, padding-top 0s ease-in-out .05s;

    .row {
        justify-content: space-between;
        pointer-events: none;
    }
}

html.firmenstaffel-de .pageheader .row {
    max-width: none;
}

.logo-container {
    position: relative;
    font-size: rem(16);
    width: 11em;
    margin: rem(80) auto 0;

    @include breakpoint(medium) {
        font-size: rem(16);
        margin: rem(20);

        html.is-home & {
            font-size: rem(20);
        }
    }

    h1 {
        font-size: inherit;
        margin: 0;
        padding: 0;
        line-height: 1;
    }

    .logo {
        z-index: 2;
        position: relative;
        display: block;
        width: 100%;
        max-width: 15em;
        //height: 12.5em;
        height: auto;
        pointer-events: initial;
        pointer-events: auto; // IE
    }
}

// Shrinked pageheader and no-hero pageheader
html.no-hero .pageheader,
.shrink.pageheader {
    top: 0;
    height: rem(72);
    padding-top: 0;
    transition: top .35s ease-in-out, padding-top 0s ease-in-out 0s;

    @include breakpoint(large) {
        position: fixed;
        background-color: rgba($pagehader-bg, 0.9);
    }

    .pagenav {
        padding-top: rem(17);
    }
}

// Overrides per event (logo sizes only)
html.firmenstaffel-de {
    .logo-container {
        margin: 0 auto;
        font-size: rem(20);
        filter: drop-shadow(0px 0px 6px rgba(5, 30, 63, 0.5));

        @include breakpoint(medium) {
            margin: rem(0 20 20);
            filter: none;
        }
    }

    @include breakpoint(medium) {
        &.is-home .logo-container {
            font-size: rem(20);
        }
    }

    &.no-hero,
    .shrink.pageheader {
        .logo-container {
            margin: 0 auto;

            @include breakpoint(medium) {
                margin: rem(-8 17 10 60);
                font-size: rem(10);
            }
        }
    }

    &.no-hero .pagecontent {
        @include breakpoint(small only) {
            padding-top: rem(230);
        }
    }
}

html.cycletour-de {
    .logo-container {
        margin: rem(65) auto 0;

        @include breakpoint(medium) {
            margin: rem(0 20);
        }

        &:after {
            z-index: 1;
            content: "";
            position: absolute;
            top: rem(-60);
            right: rem(-20);
            bottom: rem(-20);
            left: rem(-60);
            background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%);

            @include breakpoint(medium) {
                background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 70%);
            }
        }
    }

    &.no-hero,
    .shrink.pageheader {
        .logo-container {
            margin: rem(40) auto 0;

            @include breakpoint(medium) {
                margin: rem(-4 20 0);
                font-size: rem(9);
            }
        }
    }

    &.no-hero .pagecontent {
        @include breakpoint(small only) {
            padding-top: rem(176);
        }
    }
}

html.elbtalent-de {
    .logo-container {
        margin: rem(115) auto 0;

        @include breakpoint(medium) {
            margin: rem(30 20);
        }
    }

    &.no-hero,
    .shrink.pageheader {
        .logo-container {
            margin: rem(90) auto 0;

            @include breakpoint(medium) {
                margin: rem(13 30);
                font-size: rem(14);
            }
        }
    }

    &.no-hero .pagecontent {
        @include breakpoint(small only) {
            padding-top: rem(160);
        }
    }
}


.open-off-canvas {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 105;
    display: block;
    max-height: rem(72);
    padding: rem(10 15);
    color: #fff;
    background-color: rgba($primary-color, 0.9);
    outline: none;
    text-transform: uppercase;
    border-radius: 0 0 0 rem(16);



    /*
    @include breakpoint(medium) {
        padding-top: rem(24);
        padding-bottom: rem(24);
        //background: transparent;
        //border-radius: 0;
    }
    */

    @include breakpoint(large) {
        display: none;
    }

    span {
        position: relative;
        top: rem(1);
        padding-right: rem(4);
        font-size: rem(14);
        font-weight: $global-weight-bold;
    }

    .hamburger {
        fill: #fff;
        height: rem(24);
        width: rem(24);
        vertical-align: middle;

        .line-1,
        .line-3 {
            transition: transform 0.3s ease-in;
        }

        .line-2 {
            opacity: 1;
            transition: opacity 0.3s ease-out;
        }
    }

    &.active {
        .line-1 {
            transform: rotate(45deg) translate(5px, -5px);
            transform-origin: 0 0;
        }

        .line-2 {
            opacity: 0;
        }

        .line-3 {
            transform: rotate(-45deg) translate(-14px, -3px);
            transform-origin: 0 0;
        }
    }
}

html.has-socialbar .open-off-canvas {
    width: 100%;
    text-align: right;
    border-radius: 0;
}

html.firmenstaffel-de .open-off-canvas {
    background-color: rgba($primary-color, 0.9);
}

.off-canvas {
    display: block;

    nav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        display: block;
        padding: rem(5 10 5 22);
        background: #fff;
        border-bottom: 1px solid rgba(#000, 0.1);
        color: $body-font-color;
        outline: none;
        word-break: break-word;
    }

    nav>ul>li>a {
        padding-left: rem(12);
        text-transform: uppercase;
        background: $primary-color;
        color: #fff;
        font-weight: $global-weight-bold;
    }
}

.js-off-canvas-overlay {
    z-index: 101;
}

.pagenav {
    display: none;
    padding: rem(40 20 0 0);
    //transition: padding .5s ease-in-out;

    @include breakpoint(large) {
        display: block;
    }

    nav>ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        pointer-events: initial;
        pointer-events: auto; // IE

        .active>a {
            color: $pagenav-active-color-1stlevel;

            &[data-ctalink] {
                border-color: $pagenav-active-color-1stlevel;
            }
        }

        >li {
            position: relative;
        }

        >li>a {
            position: relative;
            color: $pagenav-color;
            font-weight: $global-weight-bold;
            font-family: $header-font-family;
            text-transform: uppercase;
            text-shadow: 0 0 rem(10) rgba($pagenav-shadow, 0.3);
        }

        >li.is-parent {
            a:after {
                /* pseudo element for hiding the box-shadow border between <li> and <ul> */
                content: "";
                display: block;
                position: absolute;
                height: 20px;
                background: #fff;
                top: 25px;
                left: 0;
                right: 0;
                z-index: 5;
                visibility: hidden;
                opacity: 0;
                transition: all 0.1s ease-in-out 0s, visibility 0s linear 0.25s, z-index 0s linear 0.01s;
                pointer-events: none;
            }
        }

        a {
            display: block;
            padding: rem(5 15);
            font-size: rem(16);
            border-radius: $button-radius;
            color: $body-font-color;
            background-color: rgba(#fff, 0);
            transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
            white-space: nowrap;

            &[data-ctalink] {
                border: rem(2) solid $pagenav-color;
                margin-right: rem(15);

                &:after {
                    left: rem(-2);
                    right: rem(-2);
                }
            }
        }

        /* 2nd level */
        ul {
            margin: 0;
            padding: rem(7 0);
            list-style: none;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 100%;
            left: 0;
            width: auto;
            min-width: 100%;
            transform: translateY(rem(-35));
            z-index: -1;
            transition: all 0.25s ease-in-out 0s, visibility 0s linear 0.25s, z-index 0s linear 0.01s;
            background: #fff;
            box-shadow: rem(0 0 7) rgba(0, 0, 0, 0.3);
            border-radius: $button-radius;
        }

        >li:hover {
            >a {
                background: #fff;
                color: $pagenav-active-color-2ndlevel;
                text-shadow: none !important;
                box-shadow: rem(0 0 7) rgba(0, 0, 0, 0.3);

                &:after {
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.15s ease-in-out 0s, visibility 0s linear 0.15s, z-index 0s linear 0.25s;
                }

                &[data-ctalink] {
                    border-color: #fff;
                }
            }

            a:hover,
            .active a {
                color: $pagenav-active-color-2ndlevel;
            }

            ul {
                visibility: visible;
                /* shows sub-menu */
                opacity: 1;
                z-index: 1;
                transform: translateY(0%);
                transition-delay: 0s, 0s, 0.25s;
                /* this removes the transition delay so the menu will be visible while the other styles transition */
            }
        }
    }
}

html.firmenstaffel-de {
    .pagenav nav>ul>li>a {
        text-shadow: 0 0 0.625rem rgba(5, 30, 63, 0.75);
    }

    &.no-hero,
    .shrink.pageheader {
        .pagenav nav>ul>li>a {
            text-shadow: none;
        }
    }
}
