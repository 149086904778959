@charset "UTF-8";

// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// import foundation settins and scss
//@import 'settings';
//@import 'functions';
//@import '../node_modules/foundation-sites/scss/foundation';

@import "../node_modules/modaal/dist/css/modaal.scss";

@include foundation-global-styles;

@if not $global-flexbox {
    @include foundation-grid;
}

@else {
    @include foundation-flex-grid;
    @include foundation-flex-classes;
}

@include foundation-typography;
// @include foundation-forms;
// @include foundation-button;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
@include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;

@import "accordion";
@import "atoms";
@import "buttons";
@import "cookieconsent";
@import "countdown";
@import "embed";
@import "faq";
@import "filepond";
@import "forms";
@import "gallery";
@import "hero";
@import "icongrid";
@import "lazyload";
@import "mixins";
@import "pagefooter";
@import "pageheader";
@import "pricing-table";
@import "print";
@import "progress-bar";
@import "socialbar";
@import "teaser";
@import "template";
@import "typography";
@import "utilities";

// overrides
html.cycletour-de .hero--video video,
html.cycletour-de .hero .hero--background {
    mix-blend-mode: normal;
    filter: brightness(1.1);
}

html.elbtalent-de .hero--video video,
html.elbtalent-de .hero .hero--background {
    mix-blend-mode: normal;
    filter: none;
}
