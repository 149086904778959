@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('roboto-v30-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('roboto-v30-latin-italic.woff2') format('woff2');
}
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('roboto-v30-latin-700.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local('Brandon Grotesque Thin'), url('Brandon-Grotesque-Web-Thin.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Brandon Grotesque Regular'), url('Brandon-Grotesque-Web-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local('Brandon Grotesque Regular Italic'), url('Brandon-Grotesque-Web-Regular-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Brandon Grotesque Bold'), url('Brandon-Grotesque-Web-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: local('Brandon Grotesque Bold Italic'), url('Brandon-Grotesque-Web-Bold-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 900;
    font-display: fallback;
    src: local('Brandon Grotesque Black'), url('Brandon-Grotesque-Web-Black.woff2') format('woff2');
}
@font-face {
    font-family: 'Brandon Grotesque';
    font-style: italic;
    font-weight: 900;
    font-display: fallback;
    src: local('Brandon Grotesque Black Italic'), url('Brandon-Grotesque-Web-Black-Italic.woff2') format('woff2');
}


// Project colors
$blue: #009fe3;
$pink: #e81c72;
$green: #3aaa35;
$orange: #f39200;
$purple: #951b81;
$brown: #c99e67;
$lightcolor: #F5F4F3;

$alert-color: $orange;
$success-color: #43AC6A;
$warning-color: $pink;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #ffffff;

$foundation-palette: (
                             primary: $blue,
                             secondary: $pink,
                             tertiary: $green,
                             quaternary: $orange,
                             light: $lightcolor,
                             success: $success-color,
                             warning: $warning-color,
                             alert: $alert-color
                     );
@include add-foundation-colors;
$tertiary-color: map-get($foundation-palette, tertiary);
$quaternary-color: map-get($foundation-palette, quaternary);

$body-font-color: #282828;
$body-background: #fff;
$header-color: $primary-color;
$header-font-weight: 700;
$header-small-font-color: #888;
$sidebar-background: $lightcolor;

$hero-overlay-color: #fff;
$hero-gradient-degree: 72deg;
$pagehader-bg: #fff;
$pagenav-color: $body-font-color;
$pagenav-active-color-1stlevel: $orange;
$pagenav-active-color-2ndlevel: $primary-color;
$pagenav-shadow: #fff;

// Fonts
$header-font-family: "Brandon Grotesque", serif;

// buttons
$button-background: $primary-color;
$button-radius: rem(3);
$button-styles: (
                        primary: (
                                'background': $primary-color,
                                'background-hover': scale-color($primary-color, $lightness: -20%),
                                'background-hollow-scale': -40%,
                                'text-color': #fff
                        ),
                        secondary: (
                                'background': $secondary-color,
                                'background-hover': scale-color($secondary-color, $lightness: -15%),
                                'background-hollow-scale': -35%,
                                'text-color': #fff
                        ),
                        tertiary: (
                                'background': $tertiary-color,
                                'background-hover': scale-color($tertiary-color, $lightness: -8%),
                                'background-hollow-scale': -17%,
                                'text-color': #fff
                        )
                );
