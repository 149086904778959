$social-colors: (
    facebook: #3b5998,
    twitter: #1da1f2,
    instagram: #e1306c,
    youtube: #ff0000,
    vimeo: #1ab7ea,
    strava: #FC4C02
);

.c-socialbar {
    position: absolute;
    z-index: 128;
    top: rem(-44);
    left: 0;

    @include breakpoint(large) {
        top: 65vh;
        right: 0;
        left: auto;
        transform: translateY(-50%);
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 0;
    }

    li {
        display: inline-block;

        @include breakpoint(large) {
            display: block;
        }
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: #fff;
    }

    &__item {
        display: block;
        width: rem(44);
        height: rem(44);
        padding: rem(6);
        background: #333;
        font-size: rem(16);

        @include breakpoint(large) {
            width: rem(60);
            height: rem(60);
            padding: rem(10);
        }

        &--facebook {
            background-color: map-get($social-colors, facebook);
        }

        &--youtube {
            background-color: map-get($social-colors, youtube);
        }

        &--instagram {
            background-color: map-get($social-colors, instagram);
        }

        &--strava {
            background-color: map-get($social-colors, strava);
        }

        &--twitter {
            background-color: map-get($social-colors, twitter);
        }

        &--vimeo {
            background-color: map-get($social-colors, vimeo);
        }
    }

}
