$teaser-background-color: #fff;

.teaser-container {
    margin: $typogrid*3 0;

    > .column {
        margin-bottom: $typogrid*2;
    }
}

.teaser {
    position: relative;
    height: 100%;
    background: $teaser-background-color;
    transition: box-shadow .2s ease-in-out, border-color .2s ease-in-out;
    border: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 0 rem(25) rgba(0,0,0,0.075);

    .teaser__media {
        position: relative;
        width: 100%;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
        }

        div {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            &:after {
                z-index: 2;
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: transparent;
                background-image: repeating-linear-gradient($hero-gradient-degree, rgba($hero-overlay-color,.05), rgba($hero-overlay-color,.05) 1px, transparent 1px, transparent 5px);
                background-size: 74px 500px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            //transition: transform 1s ease-in-out;
        }

        &:after {
            position: relative;
            z-index: 10;
            left: $typogrid*2;
            @include css-triangle(15px, $teaser-background-color, 'up');
        }
    }

    .teaser__content {
        padding: $typogrid*2;
        a {
            color: $primary-color;
        }
    }

    .teaser__date {
        color: #888;
    }

    .teaser__title {
        margin: 0;
        font-size: rem(18);
        line-height: $typogrid*2;
        a span {
            color: $primary-color;
        }
    }

    &:hover {
        //box-shadow: 0 0 rem(25) rgba($primary-color, 0.7);
        border-color: $primary-color;
        //background: scale-color($teaser-background-color, $lightness: -4%);
        /*.teaser__media img {
            transform: rotate(-1deg) scale(1.05);
        }*/
    }
}

.faux-block-link-overlay {
    z-index: 10;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    text-indent: 200%;
    white-space: nowrap;
    background: transparent;
    cursor: pointer;
}
