@media print {
    .pagenav, .hero {
        display: none;
    }

    .pageheader, .pageheader.shrink {
        position: static !important;
        padding: 0;
        margin: 0;

        .logo-container {
            font-size: 13px;
            margin-bottom: 0;
        }

        .logo-container a:after {
            content: "";
            content: none;
            display: none;
        }
    }

    .breadcrumbs a:after {
        content: "";
        content: none;
        display: none;
    }

    .pagefooter {
        display: none;
    }

    .footer-divider {
        display: none;
    }

    .row {
        max-width: none;
    }

    .row-background.css-mask-applied {
        margin: 0 !important;
        padding: 0 !important;
    }

    .column, .columns {
        flex-basis: 100% !important;
        max-width: 100% !important;
    }

    * {
        clip-path: none !important;
    }
}
