.footer-divider {
    position: relative;
    z-index: 9;
    width: 100%;
    height: rem(250);
    margin: rem(-10) auto rem(-30);
    overflow: hidden;
    pointer-events: none;

    img {
        position: absolute;
        left: 50%;
        width: auto;
        height: rem(250);
        max-width: none;
        min-width: 100%;
        transform: translateX(-50%);
    }

    @include breakpoint(medium) {
        height: rem(620);
        margin: rem(-100) auto rem(-100);
        margin-top: -8.9vw;

        img {
            width: rem(1920);
            height: auto;
        }
    }

    @include breakpoint(large) {
        margin-top: -10vw;
    }
}

.logoline-container {
    margin: $typogrid*10 0 $typogrid*-5;

    &:last-of-type {
        margin-bottom: $typogrid*3;
    }
}

.logoline,
.pagecontent .logoline {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: $typogrid*1 auto;
    padding: 0;
    list-style: none;

    @include breakpoint(large) {
        max-width: 90vw;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: rem(20 25);
    }
}

.partner {
    padding: 0;
    justify-content: center;

    &.partner--premium {
        padding: rem(30 0 50);

        @include breakpoint(medium) {
            padding: rem(100 0);
        }
    }

    &.partner--plus {
        padding: rem(0 0 30);
    }

    &.partner--media {
        padding: rem(20 0 30);

        @include breakpoint(medium) {
            padding: rem(80 0 40);
        }
    }

    @include breakpoint(large) {
        max-width: 90vw;
    }

    .item {
        padding: rem(20 30);
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
        filter: grayscale(1);
        transition: filter .3s ease-in-out;

        &:hover {
            filter: grayscale(0);
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }
}

.pagefooter .boxes {
    .column {
        padding: 0 rem(40) $typogrid*4;

        @include breakpoint(medium) {
            padding-left: rem(60);
            padding-right: rem(60);
        }
    }

    h3 {
        text-transform: uppercase;
    }
}

.footernav,
.footermeta {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: inline-block;
        font-size: rem(14);

        li {
            display: block;
        }
    }

    a {
        display: inline-block;
        padding: rem(0 10);
        line-height: $typogrid*2;
        color: #fff;

        &:hover {
            color: $secondary-color;
        }
    }
}

.footernav {
    background: #0e294c;
    color: #fff;
    padding: rem(40 0);

    .column>ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            margin-left: rem(-20);
            margin-right: rem(-20);
        }

        >li {
            width: 100%;
            padding: rem(10 0);
            text-align: center;

            @include breakpoint(medium) {
                width: auto;
                text-align: left;
                padding: rem(0 20);
            }
        }

        >li>a {
            text-transform: uppercase;
            font-size: $global-font-size;
            font-weight: $global-weight-bold;
        }
    }
}

.footermeta {
    background: #071426;
    color: #fff;
    padding: rem(15 0);

    ul {
        width: 100%;
    }
}

html.cycletour-de {

    .footernav,
    .footermeta {
        background: #fff;

        a {
            color: lighten($body-font-color, 20%);

            &:hover {
                color: $secondary-color;
            }
        }
    }

    .footermeta {
        background: #eee;
    }
}

html.elbtalent-de {

    .footernav,
    .footermeta {
        background: $secondary-color;
    }

    .footermeta {
        background: $primary-color;
    }
}

html.it-trendkongress-de {

    .footernav,
    .footermeta {
        background: lighten($primary-color, 50%);

        a {
            color: $body-font-color;

            &:hover {
                color: $primary-color;
                text-decoration: underline;
            }
        }

        .column>ul>li,
        .columns>ul>li {
            text-align: left;
        }
    }

    .footermeta {

        background: $white;

        a {
            color: $body-font-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
