/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *	Atom Styles
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

$extra-class-sizes: (
    'xsmall': $typogrid * 0.5,
    'small': $typogrid * 1,
    'medium': $typogrid * 2,
    'large': $typogrid * 4,
    'xlarge': $typogrid * 8,
    'xxlarge': $typogrid * 10,
);

.no-margin {
    margin: 0 !important;
}
.no-margin-top {
    margin-top: 0 !important;
}
.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}
.no-padding-top {
    padding-top: 0 !important;
}
.no-padding-bottom {
    padding-bottom: 0 !important;
}

@each $name, $size in $extra-class-sizes {
    .extra-#{$name}-margin-left {
        margin-left: $size !important;
    }
    .extra-#{$name}-margin-top {
        margin-top: $size !important;
    }
    .extra-#{$name}-margin-bottom {
        margin-bottom: $size !important;
    }
    .extra-#{$name}-padding-top {
        padding-top: $size !important;
    }
    .extra-#{$name}-padding-right {
        padding-right: $size !important;
    }
    .extra-#{$name}-padding-bottom {
        padding-bottom: $size !important;
    }
    .extra-#{$name}-padding-left {
        padding-left: $size !important;
    }
}


html.no-js {
    .show-with-js {
        display: none !important;
    }
}
html.js {
    .hide-with-js {
        display: none !important;
    }
}

@each $name, $color in $foundation-palette {
    .background--#{$name} {
        background-color: $color;
    }
}

@each $name, $color in map-merge($foundation-palette, (white: #fff)) {
    .color--#{$name} {
        color: $color !important;
    }
}
