
.progress-decoration {
    position: relative;
    height: 16vh;
}

.progress-bar {
    background-color: transparent;
    border: 2px $lightcolor solid;
    border-radius: 5px;
    position: relative;
    margin: 4px 0;
    height: 20px;
    width: 100%;
}

.progress-bar .completed-bar {
    background: linear-gradient(to right, #3096fc, #1b5298);
    border-radius: 4px;
    color: $lightcolor;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    transition: width 1.4s ease 0.4s;
}

.progress-information {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
}

.progress-information p {
    margin: 0;
}

@media only screen and (max-width: 576px) {
    .progress-decoration {
        display: none;
    }

    .progress-bar {
        margin-bottom: 4px;
    }
}
