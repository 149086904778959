.faq--title {
    font-size: rem(20);
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    color: scale-color($primary-color, $lightness: -50%);
}

.faq--searchform {

    small {
        display: block;
        text-transform: uppercase;
        line-height: $typogrid*4;
        margin: $typogrid*-2 0 0;
        font-size: $small-font-size;
        color: $header-small-font-color;
        font-family: $header-font-family;
        text-align: center;
    }
}

.faq--searchwrapper {
    max-width: rem(500);
    margin: rem(20) auto rem(80);
}

.faq--searchfield, .faq--searchfield:valid, .faq--searchfield.filled {
    position: static;
    font-size: rem(30);
    color: $primary-color;
    text-align: center;
    top: 0;
    margin: 0;
    appearance: none;
    border-radius: 0;


    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: $primary-color;
        opacity: 1;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $primary-color;
        opacity: 1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $primary-color;
        opacity: 1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $primary-color;
        opacity: 1;
    }
}

.faq--searchresults {
    margin-bottom: rem(100);

    mark {
        background-color: #ff0;
        color: inherit;
        padding: rem(0 1);
    }
}
